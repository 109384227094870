import React from 'react';

import Container from '../components/Container';
import Masthead from '../components/Masthead';
import IconSearch from '../components/IconSearch';
import Page from '../components/Page';

function IndexPage() {
  return (
    <Page>
      <Container>
        <Masthead />
        <IconSearch />
      </Container>
    </Page>
  );
}

export default IndexPage;
